.boxLeft {
  background-color: white;
  color: black;
  display: grid;
  grid-template-columns: 40% 60%;
  height: 400px;
}

.photoLeft img {
  width: 100%;
  height: 400px;
}

.contentLeft {
  padding: 20px 50px;
}

.contentLeft h1 {
  font-size: 2.5em;
  margin-bottom: 0;
}

.contentLeft h3 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 700;
  margin: 22px 0;
}

.contentLeft p {
  font-size: 1.4em;
}

.boxRight {
  background-color: black;
  color: white;
  display: grid;
  grid-template-columns: 60% 40%;
  height: 420px;
}

.photoRight img {
  width: 100%;
  height: 420px;
}

.contentRight {
  padding: 20px 50px;
  text-align: right;
}

.contentRight h1 {
  font-size: 2.5em;
  margin-bottom: 0;
}

.contentRight h3 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 700;
  margin: 20px 0;
}

.contentRight p {
  font-size: 1.4em;
}

@media screen and (max-width: 1100px) {
  .boxLeft,
  .boxRight {
    height: 490px;
    box-sizing: border-box;
    overflow: hidden;
    grid-template-columns: 50% 50%;
  }

  .photoLeft img,
  .photoRight img {
    height: 100%;
  }

  .contentLeft,
  .contentRight {
    padding: 20px;
  }

  .contentLeft h1,
  .contentRight h1 {
    font-size: 40px;
  }

  .contentLeft p,
  .contentRight p {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .boxLeft,
  .boxRight {
    display: flex;
    flex-direction: column;
    height: 700px;
    text-align: center;
  }

  .boxRight {
    flex-direction: column-reverse;
    border-bottom: 2px solid white;
  }

  .photoLeft,
  .photoRight {
    padding-top: 0;
    margin-top: 0;
  }

  .contentLeft,
  .contentRight {
    padding: 20px 10px;
    text-align: center;
  }

  .contentLeft h1,
  .contentRight h1 {
    font-size: 35px;
    padding: 0;
  }
}
