.container {
  box-sizing: border-box;
  overflow: hidden;
}

.slide {
  height: 600px;
  margin: 0 7.5%;
  background-image: url("../../Images/rhema-kallianpur-uocSnWMhnAs-unsplash.jpeg");
  background-size: cover;
  text-align: center;
}

.slide h1 {
  font-size: 5em;
  margin: 0;
  padding-top: 250px;
}

.info {
  text-align: center;
  font-size: 1.5em;
  margin: 50px 0;
  font-weight: 400;
}

.ourHotels {
  width: 100%;
}

.sliderInfo {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 30px;
}

.upcomingHotels {
  width: 100%;
  height: 800px;
  text-align: center;
  padding: 30px 0;
}

.upcomingHotels ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  padding: 40px 80px;
}

.upcomingHotels h1 {
  font-size: 3em;
}

.upcomingHotels p {
  font-size: 2em;
  height: 100px;
  margin: 20px 0;
}

.upcomingNameContainer {
  height: 300px;
  width:400px;
  margin-bottom: 20px;
  padding: 10px 40px;
  border: 2px solid red;
  /* background-color: white;
  color: black;
  */
} 

.upcomingNameContainer img {
  width: 100%;
  height: 65%;
}

@media screen and (max-width: 1100px) {
  .upcomingHotels {
    height: 1100px;
  }

  .upcomingHotels ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px 0;
    box-sizing: border-box;
  }

  .upcomingNameContainer {
    width: 95%;
    height: 300px;
    margin-left: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px)  {
  .slide {
    height: 400px;
  }

  .slide h1 {
    padding-top: 150px;
    font-size: 55px;
  }

  .sliderInfo h1{
    font-size: 40px;
  }

  .upcomingHotels {
    height: 2100px;
  }

  .upcomingHotels ul {
    display: block;
    padding: 0;
    box-sizing: border-box;
  }

  .upcomingNameContainer {
    width: 95%;
    height: 300px;
    margin-left: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
  }
}