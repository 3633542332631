.slider-container {
  height: 650px;
  width: 80%;
  position: relative;
  margin: auto;
  overflow: hidden;
  background-color: black;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: 650px;
  width: 100%;
  position: relative;
}

.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: fill;
}

.slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: #f8f0e1;
  /* color: black; */
  font-size: 85px;
  position: absolute;
  text-align: center;
  top: 10%;
  z-index: 10;
  font-family: '';
  font-family: 'Playfair Display', serif;
}

.slide-text {
  top: 92%;
  font-size: 25px;
  font-family: 'Helvetica';
}

.prev,
.next {
  color: transparent;
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 45%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 60px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
}

.slider-container:hover .prev,.slider-container:hover .next {
  color: rgb(149, 144, 144)
}

.slider-container:hover .prev:hover,
.slider-container:hover .next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 90%;
  justify-content: center;
  z-index: 200;
}

.dot {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0px 3px;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border-radius: 50%;
  display: inline-block;
}

.slider-container:hover .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* .active-dot {
  background-color: rgba(255, 255, 255, 0.5);
} */

.slider-container:hover .dot{
  background-color: rgba(0, 0, 0, 0.3);
}
.slider-container:hover .active-dot{
  background-color: rgba(255, 255, 255, 0.5);
}


.play-pause {
  color: black;
}

@media screen and (max-width: 600px) {
  .slide-title {
    font-size: 30px;
    top: 60%;
  }
  
  .slide-text {
    font-size: 15px;
    top: 92%;
  }

  .slide-image {
    object-fit: fill;
    overflow: hidden;
  }

  .slider-container {
    height: 350px;
    width: 100%;
    object-fit: fill;
  }

  .slides {
    height: 350px;
  }

  .dot {
    height: 1rem;
    width: 1rem;
  }
} 

@media screen and (min-width: 900px) and (max-width: 1100px) {
  .slider-container {
    height: 450px;
    border: 1px solid black;
  }

  .slides {
    height: 450px;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .slider-container {
    height: 450px;
  }

  .slides {
    height: 450px;
  }
}

@media screen and (max-width: 600px) {
  .slider-container {
    height: 400px;
  }

  .slides {
    height: 400px;
  }
}