.container {
  text-align: center;
  width: 600px;
  border: 1px solid white;
  box-sizing: border-box;
  overflow: hidden;
}

.containerPic {
  height: 400px;
  width: 80%;
  cursor: pointer;
  transition: all 0.3s linear;
}

.containerPic:hover {
  transform: scale(1.05);
}

.container h1 {
  font-size: 3em;
  margin: 10px 0;
}

.container p {
  font-size: 1.2em;
  padding: 10px 30px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.popup img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  /* max-width: 95%;
  max-height: 95%; */
  height: 580px;
  width: 39%;
  padding: 20px;
  object-fit: contain;
  border: 3px solid white;
} 

.popup span {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 50px;
  font-weight: bolder;
  z-index: 1200;
  cursor: pointer;
  color: #fff;
  user-select: none;
}

@media screen and (max-width: 1100px) {
  .container {
    width: 99%;
    height: 440px;
    margin: 30px 0.5%;
    padding: 0;
    box-sizing: border-box;
  }

  .containerPic {
    height: 300px;
    width: 100%;
  }

  .container h1 {
    margin: 0;
  }

  .container p {
    padding: 0;
  }

  .popup img {
    width: 100%;
    height: 450px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 98%;
    height: 440px;
    margin: 30px 0.5%;
    padding: 0;
    box-sizing: border-box;
  }

  .containerPic {
    height: 300px;
    width: 100%;
  }

  .container h1 {
    margin: 0;
  }

  .container p {
    padding: 0;
  }

  .popup img {
    width: 100%;
    height: 450px;
  }
}
