.title {
  text-align: center;
  height: 600px;
  background-image: url("../../Images/DSC00126.jpg");
  background-size: cover;
  background-position: center;
  margin: 0 7.5%;
  border-radius: 40px;
}

.title h1 {
  font-size: 5em;
  padding-top: 20px;
}

.infoContainer {
  text-align: center;
  margin: 30px 12%;
}

.infoContainer h1 {
  font-size: 3.5em;
  font-weight: 500;
}

.infoContainer p {
  font-size: 1.5em;
  font-weight: 300;
  margin: 20px 0;
}

@media screen and (min-width: 1200px) {}

@media screen and (max-width: 768px)  {
  .title {
    height: 250px;
  }

  .infoContainer {
    margin: 30px 5%;
  }
}