.slide {
  background-image: url("../../Images/image3.jpg");
  background-size: cover;
  background-position: center;
  margin: 0 7.5%;
  height: 600px;
  text-align: center;
}

.slide h1 {
  font-size: 5em; 
  margin: 0;
  padding: 0;
  padding-top: 250px;
}

.info {
  margin: 35px 7.5%;
  text-align: center;
}

.info h1 {
  font-size: 2.6em;
  font-weight: 500;
  margin-bottom: 5px;
}

.info h5 {
  font-size: 1.5em;
  font-weight: 200;
  margin-top: 10px;
}

@media screen and (max-width: 1100px) {
  
}

@media screen and (max-width: 768px) {
  .container {
    box-sizing: border-box;
    overflow: hidden;
  }

  .slide {
    height: 400px;
  }

  .slide h1 {
    font-size: 45px;
    padding-top: 100px;
  }

  .info {
    margin: 35px 7%;
  }

  .info h1 {
    font-size: 40px;
  }

  .info h5 {
    font-size: 20px;
  }
}
