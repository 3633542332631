* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --heights: 110vh;
  --widths: 100%;
}

.slidercontain {
  height: 650px;
  width: var(--widths);
  position: relative;
  margin: auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: 650px;
  width: 100%;
  position: relative;
}

.sliderImage {
  width: 50%;
}

.imageroom {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: fill;
}

.sliderPTag {
  padding: 35px;
  padding-top: 10px;
  width: 100%;
  color: black;
  background-color: white;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.sliderPTag h1 {
  font-size: 60px;
  margin-bottom: 15px;
  color: black;
}

a {
  text-decoration: none;
}

.sliderPTag h1:hover {
  text-decoration: underline;
  cursor: pointer;
}

.sliderPTag p {
  font-size: 19px;
  white-space: pre-wrap;
  padding: 0;
}

.sliderPTag img {
  width: 9em;
  margin-bottom: 5px;
}

.footerLine {
  width: 100% !important;
  object-fit: fill;
  padding: 0;
  margin: 0 !important;
  position: absolute;
  bottom: -0.8%;
  mix-blend-mode: lighten;
}

.prevImage,
.nextImage {
  color: transparent;
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 40px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
}

.slidercontain:hover .prevImage,
.slidercontain:hover .nextImage {
  color: black;
}

.slidercontain:hover .prevImage:hover,
.slidercontain:hover .nextImage:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.nextImage {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 85%;
  left: 25%;
  justify-content: center;
  z-index: 200;
}

.dots {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0px 3px;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border-radius: 50%;
  display: inline-block;
}

.slidercontain:hover .dots:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* .active-dot {
  background-color: rgba(255, 255, 255, 0.5);
} */

.slidercontain:hover .dots {
  background-color: rgba(0, 0, 0, 0.3);
}
.slidercontain:hover .active-dots {
  background-color: rgba(255, 255, 255, 0.5);
}

.play-pause {
  color: black;
}

@media screen and (max-width: 1100px) {
  .slidercontain {
    display: flex;
    flex-direction: column-reverse;
    height: 1000px;
    /* margin-bottom: 5px; */
    background-color: #8b313d;
  }

   .sliderImage {
    height: 50%;
  }

  .slides {
    height: 550px;
  }

  .imageroom {
    height: 100%;
    width: 100%;
  }

  .sliderPTag {
    height: 450px;
    padding: 10px 12px;
  }

  .sliderPTag h1 {
    font-size: 50px;
    margin-bottom: 5px;
  }

  .sliderPTag img {
    width: 7em;
    margin-bottom: 5px;
  }

  .sliderPTag p {
    font-size: 15px;
  }

  .all-dot {
    top: 52%;
    left: 0%;
  }

  .prevImage,
  .nextImage {
    top: 25%;
  }

  .nextImage {
    right: 0%;
  }
   .footerLine {
    width: 750px !important;
    height: 130px;
    box-sizing: border-box;
    text-align: center;
    position: absolute; 
    left: 18%;
  }
}

@media screen and (max-width: 900px) {
  .slidercontain {
    display: flex;
    flex-direction: column-reverse;
    height: 1000px;
    /* margin-bottom: 5px; */
    background-color: #8b313d;
  }

   .sliderImage {
    height: 50%;
  }

  .slides {
    height: 550px;
  }

  .imageroom {
    height: 100%;
    width: 100%;
  }

  .sliderPTag {
    height: 450px;
    padding: 10px 12px;
  }

  .sliderPTag h1 {
    font-size: 50px;
    margin-bottom: 5px;
  }

  .sliderPTag img {
    width: 7em;
    margin-bottom: 5px;
  }

  .sliderPTag p {
    font-size: 15px;
  }

  .all-dot {
    top: 52%;
    left: 0%;
  }

  .prevImage,
  .nextImage {
    top: 25%;
  }

  .nextImage {
    right: 0%;
  }
   .footerLine {
    width: 450px !important;
    height: 100px;
    box-sizing: border-box;
    text-align: center;
    position: absolute; 
    left: 20%;
  }
}

@media screen and (max-width: 600px) {
  .slidercontain {
    display: flex;
    flex-direction: column-reverse;
    height: 950px;
    /* margin-bottom: 5px; */
    background-color: black;
    /* height: 500px; */
    /* overflow: hidden; */
  }

  .sliderImage {
    height: 50%;
  }

  .slides {
    height: 400px;
  }

  .imageroom {
    height: 100%;
    width: 100%;
  }

  .sliderPTag {
    height: 550px;
    padding: 10px 12px;
  }

  .sliderPTag h1 {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .sliderPTag img {
    width: 5em;
    margin-bottom: 5px;
  }

  .sliderPTag p {
    font-size: 14px;
  }

  .all-dot {
    top: 38%;
    left: 0%;
  }

  .prevImage,
  .nextImage {
    top: 25%;
  }

  .nextImage {
    right: 0%;
  }

  .footerLine {
    width: 250px !important;
    height: 70px;
  }
}
