.container {
  /* height: 600px; */
  text-align: center;
  margin: 50px 7.5%;
  box-sizing: border-box;
}

.info h1 {
  font-size: 3em;
  margin-bottom: 10px;
}

.info h5 {
  margin: 0;
  font-size: 1.3em;
  font-weight: 300;
}

.boxContainer {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-between;
  margin-top: 30px;
}

@media screen  and (max-width: 1100px) {
  .boxContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 20px 10px;
  }

  .info h1 {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .info h5 {
    font-size: 20px;
  }

  .boxContainer {
    display: block;
  }
}
