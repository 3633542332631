.container {
  text-align: center;
  height: 570px;
  width: 400px;
  color: black;
  background-color: white;
  text-decoration: none;
  border-radius: 50px;
  box-sizing: border-box;
  overflow: hidden;
}

.container img {
  height: 250px;
  width: 100%;
}

.container h1 {
  font-size: 2em;
  margin: 10px;
}

.container h3 {
  font-size: 1.5em;
  font-weight: 400;
  margin: 10px;
}

.container p {
  font-size: 1em;
  margin: 15px 0;
  padding: 0 5px;
}

.container button {
  font-size: 1em;
  height: 40px;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 40px;
  margin-top: 5px;
}

@media screen and (max-width: 1270px) {
  .container {
    width: 80%;
    margin: 20px 10%;
  }

  .container img {
    height: 290px
  }
}

@media screen and (max-width: 768px)  {
  .container {
    width: 100%;
    height: 650px;
    margin: 15px 0;
  }

  .container img {
    width: 100%;
  }

  .container p {
    font-size: 19px;
    padding: 2px 20px;
  }
}