.container {
  box-sizing: border-box;
  overflow: hidden;
}

.titleContainer {
  height: 600px;
  margin: 0 7.5%;
  background-image: url("../../Images/jewelery.jpeg");
  background-size: cover;
  background-position: center;
  text-align: center;
  border-radius: 50px;
}

.titleContainer h1 {
  font-size: 4em;
  padding-top: 300px;
}

.infoContainer {
  text-align: center;
  margin: 2% 7.5%;
}

.infoContainer h1 {
  font-size: 3em;
  padding: 0 150px;
  font-weight: 500;
}

.infoContainer p {
  font-size: 1.5em;
  padding: 10px 140px;
  font-weight: 200;
}

.jewelBoxContainer {
  box-sizing: border-box;
}

.jewelBox1,
.jewelBox2,
.jewelBox3 {
  display: flex;
  justify-content: space-between;
  /* grid-template-columns: 1fr 1fr 1fr; */
  margin: 40px 5%;
}

@media screen and (max-width: 1100px) {
  .infoContainer {
    margin: 30px 0;
    padding: 0;
  }

  .infoContainer h1 {
    padding: 10px 60px;
  }

  .infoContainer p {
    padding: 10px 40px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    box-sizing: border-box;
  }

  .titleContainer {
    height: 500px;
  }

  .titleContainer h1 {
    padding-top: 160px;
    font-size: 45px;
  }

  .infoContainer {
    margin: 0;
    padding: 40px 15px;
  }

  .infoContainer h1 {
    padding: 0;
  }

  .infoContainer p {
    padding: 0;
  }

  .jewelBox1,
  .jewelBox2,
  .jewelBox3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
  }
}
