.container {
  /* height: 400px; */
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 20px 50px;
}

.title {
  font-size: 1.4em;
}

.boxContainer {
  display: flex;
  margin: 20px 10%;
  height: 250px;
}

.box1 {
  width: 50%;
  text-align: left;
  padding-left: 100px;
  padding-top: 25px;
  box-sizing: border-box;
  border-right: 1px solid white;
}

.box1 h3,
.box2 h3 {
  font-size: 1.5em;
}

.box1 h3 {
  font-size: 2.5em;
}

.box1 hr {
  width: 100px;
  align-items: left;
  margin: 50px 0;
}

.box1 h6 {
  font-size: 1.5em;
  font-weight: 300;
}

.box1 p {
  font-size: 1em;
  padding-top: 10px;
}

.box2 {
  width: 50%;
  text-align: left;
  padding-left: 100px;
  padding-top: 10px;
  box-sizing: border-box;
}

.box2 h4 {
  font-size: 1em;
  font-weight: 500;
}

.box2 hr {
  width: 100px;
  align-items: left;
  margin: 25px 0;
}