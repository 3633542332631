.container {
  font-family: "Figtree";
}

.titleContainer {
  text-align: center;
  padding: 45px 250px;
}

.titleContainer h1 {
  font-size: 3rem;
}

.titleContainer p {
  font-size: 1.5rem;
}

.ourStory {
  height: 400px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 60% 40%;
  margin: 0 7.5%;
}

.storyImage img {
  height: 400px;
  width: 100%;
}

.storyText {
  padding: 20px 50px;
}

.storyText h1 {
  font-weight: 200;
  font-size: 2.5em;
}

.storyText h3 {
  font-size: 1.5em;
  margin: 10px 0;
}

.storyText p {
  font-size: 1.2em;
}

.types {
  width: 100%;
  height: 1440px;
  padding: 50px 0;
}

.typeBox1,
.typeBox2 {
  height: 400px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.typeBoxImage1,
.typeBoxImage2 {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.typeBoxImage1 img,
.typeBoxImage2 img {
  width: 100%;
  height: 100%;
}

.typeBoxText1,
.typeBoxText2 {
  width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
  text-align: center;
}

.typeBoxText1 {
  background-color: white;
  color: black;
}

.types h1 {
  font-size: 3.2em;
  margin-bottom: 40px;
  text-align: center;
}

.types h2 {
  font-size: 1.6em;
  font-weight: 400;
}

.types h3 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px 0;
  margin-bottom: 20px;
}

.types p {
  font-size: 1.3em;
  margin-bottom: 40px;
}

.typeBox1 button {
  text-decoration: none;
  background-color: transparent;
  color: black;
  border: 2px solid black;
  font-size: 1.4em;
  padding: 20px 50px;
  cursor: pointer;
}

.typeBox1 button:hover {
  color: white;
  background-color: black;
}

.typeBox2 button {
  text-decoration: none;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  font-size: 1.4em;
  padding: 20px 50px;
  cursor: pointer;
}

.typeBox2 button:hover {
  color: black;
  background-color: white;
}

@media screen and (max-width: 1100px) {
  .titleContainer {
    padding: 50px 70px;
  }

  .ourStory {
    display: inline;
  }

  .storyImage img {
    width: 80%;
    margin: 0 10%;
  }

  .storyText {
    padding: 20px 80px;
    text-align: center;
  }

  .types {
    height: 2200px;
  }

  .typeBox1, .typeBox2 {
    height: 600px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    box-sizing: border-box;
    overflow: hidden;
  }

  .titleContainer {
    margin: 0;
    padding: 50px 40px;
  }

  .titleContainer h1 {
    font-size: 40px;
    margin-bottom: 25px;
  }

  .titleContainer p {
    font-size: 20px;
  }

  .ourStory {
    display: inline-block;
  }

  .storyImage img {
    height: 300px;
    width: 100%;
    margin-left: 0;
  }

  .storyText {
    padding: 0;
    text-align: center;
  }

  .storyText h3 {
    font-size: 25px;
  }

  .types {
    height: 3190px;
  }

  .typeBox1,
  .typeBox2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1000px;
  }

  .typeBox2 {
    flex-direction: column-reverse;
  }

  .typeBoxImage1 {
    width: 100%;
    height: 50%;
  }

  .typeBoxImage2 {
    width: 100%;
    height: 50%;
  }

  .typeBoxText1 {
    height: 50%;
    width: 100%;
    padding: 10px;
    margin: 0;
  }

  .typeBoxText1 h1 {
    margin: 10px;
  }

  .typeBoxText1 p {
    padding: 10px;
  }

  .DSHM {
    height: 1150px;
    padding: 5px;
  }

  .typeBoxText2 {
    height: 50%;
  }
}
