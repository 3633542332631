.container {
  padding-bottom: 60px;
}

.title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 30px;
}

.boxContainer {
  margin: 0 5%;
  display: flex;
  justify-content: space-between;
}

.box {
  width: 31%;
  height: 500px;
  box-sizing: border-box;
  background-color: white;
  color: black;
  text-align: center;
}

.video {
  width: 100%;
  height: 65%;
  border: none;
}

.text h1 {
  font-size: 1.8em;
  font-weight: 500;
  margin: 15px 0;
}


@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .title {
    font-size: 25px;
    margin: 30px 0;
  }

  .boxContainer {
    display: inline-block;
    box-sizing: border-box;
    overflow: hidden;
  }

  .box {
    width: 98%;
    height: 500px;
    box-sizing: border-box;
    margin-left: 2%;
    margin-bottom: 20px;
  }

  .video {
    width: 100%;
    height: 60%;
    box-sizing: border-box;
  }

  .video iframe {
    width: 100%;
    box-sizing: border-box;
    height: 300px;
  }

  .text {
    padding: 2px 5px;
  }
}

@media screen and (max-width: 1100px) {
  .container {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .title {
    font-size: 25px;
    margin: 30px 0;
  }

  .boxContainer {
    display: inline-block;
    box-sizing: border-box;
    overflow: hidden;
  }

  .box {
    width: 98%;
    height: 500px;
    box-sizing: border-box;
    margin-left: 2%;
    margin-bottom: 20px;
  }

  .video {
    width: 100%;
    height: 65%;
    box-sizing: border-box;
  }

  .video iframe {
    width: 100%;
    box-sizing: border-box;
    height: 300px;
  }

  .text {
    padding: 2px 5px;
  }
  
}
