.container {
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: black;
  position: relative;
  z-index: 1000;
  color: white;
  position: sticky;
  top: 0;
}

.logoImage img {
  height: 100px;
  width: 200px;
  margin-left: 70px;
  position: absolute;
  top: 10%;
  left: 38%;
}

.linkContainer {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-top: 50px;
}

.linker {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.leftNav {
  float: left;
}

.rightNav {
  float: right;
}

/* .linker a:active,
.linker a.active {
  color: #de4512;
  padding-bottom: 0.25rem;
  border-bottom: 4px solid #de4512;
} */

.navLink {
  text-decoration: none;
  color: white;
  margin-left: 3rem;
  font-weight: bold;
}

.nav-btn {
  height: 50px;
  margin-left: 50px;
  margin-top: -10px;
  cursor: pointer;
  background-color: white;
  box-sizing: border-box;
  border: none;
  outline: none;
  color: black;
  visibility: hidden;
  opacity: 0;
  font-size: 2rem;
  border-radius: 40px;
}

@media only screen and (max-width: 1200px) {
  .container {
    display: grid;
    grid-template-columns: 80% 20%;
  }

  .logoImage img{
    left: 30%;
  }

  .linkContainer {
    display: inline-block;
  }

  .leftNav,
  .rightNav {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: 50%;
    padding: 0;
  }

  .navLink {
    margin: 0;
    padding: 0;
    font-size: 2rem;
  }

  .nav-btn {
    visibility: visible;
    opacity: 1;
    color: white;
    background-color: transparent;
    width: 100px;
  } 

  .nav-btn:hover {
    background-color: transparent;
    color: white;
    cursor: pointer;
  }

  header .linker {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    color: white;
    font-size: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* gap: 2rem; */
    background-color: black;
    transition: 1s;
    transform: translateY(-150vh);
    box-sizing: border-box;
    overflow: hidden;
    /* padding-right: 40px; */
    padding: 150px;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 4rem;
    right: 1rem;
    border: none;
    background-color: transparent;
    color: white;
  }
}

@media screen and (max-width: 768px) {
  .container {
    height: 130px;
    grid-template-columns: 40% 60%;
    box-sizing: border-box;
    overflow: hidden;
    position: sticky;
  }

  .logoImage img {
    margin-left: -30px;
    margin-top: 2px;
  }

  .nav-btn {
    margin-top: 2px;
    margin-left: 65%;
  }

  header .linker {
    font-size: 40px;
    /* gap: 0.1rem; */
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 100px 2px;
  
  }

   nav .nav-close-btn {
    position: absolute;
    top: 1rem;
    right: 0;
    border: none;
    background-color: transparent;
    color: white;
  }

}
