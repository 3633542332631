.container {
  box-sizing: border-box;
  overflow: hidden;
}

.title {
  margin: 0 7.5%;
  text-align: center;
  height: 600px;
  background-image: url("../../Images/contact.jpeg");
  background-size: cover;
  background-position: center;
}

.title h1 {
  font-size: 5em;
  padding-top: 300px;
}

.infoContainer {
  text-align: center;
  margin: 40px 20%;
}

.infoContainer h1 {
  font-size: 3.5em;
  font-weight: 500;
  margin-bottom: 20px;
}

.infoContainer p {
  font-size: 1.5em;
  font-weight: 300;
}
/*
.nameTag {
  font-size: 1.5em;
  text-align: center;
  font-weight: 300;
}

.dosContainer {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 30px 7.5%;
}

.dosBox1,
.dosBox2 {
  height: 150px;
  border-right: 1px solid white;
  padding-top: 5px;
}

.dosBox3 {
  height: 150px;
  padding-top: 5px;
}

.dosContainer h2 {
  font-size: 30px;
  margin: 20px 0;
}

.dosContainer p {
  font-size: 18px;
  margin: 20px 0;
} */

.headquarterContainer {
  display: grid;
  grid-template-columns: 35% 65%;
  margin: 0 7.5%;
  height: 600px;
  box-sizing: border-box;
}

.headquarterContainerText {
  padding: 80px 25px;
}

.headquarterContainerText h1 {
  font-size: 45px;
  font-weight: 500;
}

.headquarterContainerText p {
  font-size: 25px;
  margin-top: 20px;
  font-weight: 300;
}

.headquarterContainerImage img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1270px) {
  .headquarterContainer {
    display: grid;
    grid-template-columns: 40% 60%;
    height: 500px;
  }

  .headquarterContainerText {
    box-sizing: border-box;
    padding: 40px 25px;
  }

  .headquarterContainerText h1 {
    font-size: 35px;
  }

  .headquarterContainerText p {
    font-size: 23px;
  }

}

@media screen and (max-width: 1060px) {
  .title {
    height: 500px;
    width: 100%;
    margin: 0;
  }

  .title h1 {
    padding-top: 90px;
  }

  .infoContainer {
    margin: 50px 40px;
  }

  .headquarterContainer {
    display: flex;
    flex-direction: column-reverse;
    height: 940px;
  }

  .headquarterContainerText {
    text-align: center;
    padding: 40px 25px
  }
}

@media screen and (max-width: 768px) {
  .title {
    height: 400px;
  }

  .title h1 {
    font-size: 45px;
    padding-top: 100px;
  }

  .infoContainer {
    margin: 30px 10px;
  }

  .infoContainer h1 {
    font-size: 40px;
  }

  .infoContainer p{
    font-size: 20px;
    padding: 0 10px;
  }

  .headquarterContainer {
    display: flex;
    flex-direction: column-reverse;
    height: 1000px;
    border-bottom: 2px solid white;
  }

  .headquarterContainerText {
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .headquarterContainerText h1 {
    font-size: 40px;
    margin: 15px 0;
  }

  .headquarterContainerText p {
    font-size: 22px;
  }

  .headquarterContainerImage {
    height: 50%;
  }
}
