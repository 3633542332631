.containerValues {
  padding: 20px 0;
  box-sizing: border-box;
  border: 1px solid white;
}

.containerValues img {
  width: 100px;
  height: 100px;
}

.containerValues hr {
  width: 25%;
  margin: 10px 0;
  margin-left: 37%;
}

.containerValues h1 {
  font-size: 24px;
  font-size: 600;
  margin: 10px 0;
}

.containerValues p {
  font-size: 1.2em;
  font-weight: 300;
  padding: 0 15px;
}

@media screen and (max-width: 1100px) {
  .containerValues {
    width: 95%;
    height: 300px;
    margin: 10px;
  }
}

@media screen and (max-width: 768px)  {
  .containerValues {
    margin: 30px 10px;
  }
}