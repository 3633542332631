.container {
  box-sizing: border-box;
  overflow: hidden;
}

.title {
  text-align: center;
  background-image: url("../../Images/newsRoomCover.jpg");
  background-size: cover;
  background-position: top;
  height: 600px;
  margin: 0 7.5%;
}

.title h1 {
  font-size: 5em;
  padding-top: 220px;
}

.infoContainer {
  text-align: center;
  padding: 40px 250px;
}

.infoContainer h1 {
  font-size: 3em;
  font-weight: 500;
}

.infoContainer p {
  font-size: 1.5em;
  font-weight: 300;
  margin-top: 20px;
}

.newsBoxContainer {
  margin: 0 5%;
  display: grid;
}

.newsBox1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 4%;
}

.newsBox2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 1270px) {
  .title {
    height: 500px;
  }

  .infoContainer {
    padding: 50px 40px;
  }

  .newsBox1 {
    display: inline-block;
  }
}

@media screen and (max-width: 768px) {
  .title {
    height: 400px;
  }

  .title h1 {
    font-size: 50px;
    padding-top: 190px;
  }

  .infoContainer {
    padding: 40px 10px;
  }

  .infoContainer h1 {
    font-size: 35px;
  }

  .infoContainer p {
    font-size: 20px;
  }

  .newsBoxContainer {
    margin: 0;
  }

  .newsBox1 {
    display: inline-block;
  }
}


