.container {
  min-height: 50vh;
  display: grid;
  place-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  color: black;
}

.title {
  font-size: 1em;
  border: 3px solid black;
  margin-top: 20px;
  padding: 0 30px;
}

.slider {
  height: 250px;
  margin: auto;
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track {
  display: flex;
  width: calc(300px * 18);
  animation: scroll 30s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 5));
  }
}

.slide {
  height: 200px;
  width: 300px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

img {
  width: 100%;
  height: 200px;
  transition: transform 1s;
}

img:hover {
  transform: translateZ(20px);
}

/* .slider ::before,
.slider ::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}

.slider ::before {
  left: 0;
  top: 0;
}

.slider ::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
} */

@media screen and (max-width: 1100px) {
  .container {
    height: 350px;
  }
}